<template>
  <div class="container">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$init();
  },
};
</script>

<style lang="less">
/* 页面宽度小于768px
------------------------------- */
@media screen and (max-width: 768px) {
  // 滚动条的宽度
  ::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: var(--next-bg-main-color);
  }
  // 滚动条的设置
  ::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 5px;
    transition: 0.3s background-color;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, 0.5);
  }
  // element plus scrollbar
  .el-scrollbar__bar.is-vertical {
    width: 2px !important;
  }
  .el-scrollbar__bar.is-horizontal {
    height: 2px !important;
  }
}

/* 页面宽度大于768px
------------------------------- */
@media screen and (min-width: 769px) {
  // 滚动条的宽度
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: var(--next-bg-main-color);
  }
  // 滚动条的设置
  ::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 5px;
    transition: 0.3s background-color;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, 0.5);
  }
}

.container-page {
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  overflow-y: scroll;
  height: calc(100vh - 64px);
}
@import 'lib/style/gg.css';
.cm {
  color: #0a5fe4;
}
.cerr {
  color: #ec4135;
}
.bgm {
  background: #0a5fe4;
}
.btn-pri {
  width: 678rpx;
  height: 100rpx;
  background: #0a5fe4;
  border-radius: 50rpx;
}
.btn-pri-plain {
  padding: 8rpx 16rpx;
  background: #c4f0d9;
  color: #0a5fe4;
  font-weight: border;
  border-radius: 6rpx;
}
.btn-err-plain {
  padding: 8rpx 16rpx;
  background: #fad2cf;
  color: #ec4135;
  font-weight: border;
  border-radius: 6rpx;
}
.bb1f4 {
  border-bottom: 1px solid #f4f4f4;
}
</style>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* 去除谷歌浏览器input输入框选中情况下的背景色 */
input:-webkit-autofill {
  transition: background-color 500000s ease-in-out 0s;
}
</style>
