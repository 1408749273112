// import { utils as xlsxUtils, writeFile } from 'xlsx';
// import { utils as xlsxUtils, writeFile } from '/xlsx.min.js';
let { writeFile } = XLSX;
let xlsxUtils = XLSX.utils;
export default ({
  column, // 列 [{
  //     title: '用户身份',
  //     key: 'type',
  //     type:"text",
  //   },]
  data, // 数据  [{type:1}]
  excelName, //文件名称
  autoWidth = true, // 自动宽度
}) => {
  let worksheet;
  worksheet = xlsxUtils.json_to_sheet(
    data.map((item) => {
      let re = {};
      for (const index in column) {
        let colItem = column[index];
        re[colItem.title] = item[colItem.key];
      }
      return re;
    })
  );
  xlsxUtils.sheet_add_aoa(
    worksheet,
    [
      column.map((colItem) => {
        return colItem.title;
      }),
      //   [
      //     '时间',
      //     '区域平均最低气温距平（℃）',
      //     // '区域平均最低气温气候态（℃）',
      //     // '最低气温值（℃）'
      //   ]
    ],
    { origin: 'A1' }
  );
  for (let key in worksheet) {
    if (key.indexOf('!') !== 0) {
      if (key.replace(/[^0-9]/gi, '') === '1') {
        worksheet[key]['s'] = {
          font: {
            // sz: 20,//设置标题的字号
            bold: true, //设置标题是否加粗
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          }, //设置标题水平竖直方向居中，并自动换行展示
          fill: {
            fgColor: { rgb: 'ebebeb' }, //设置标题单元格的背景颜色
          },
        };
      } else {
        worksheet[key]['s'] = {
          font: {
            // sz: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
        };
      }
    }
  }
  //   for (const key in worksheet) {
  //     if (key.replace(/[^0-9]/ig, '') === '1') {
  //       // colsList.push({ wpx: 150 })
  //       worksheet[key].s = {
  //         alignment: {
  //           horizontal: 'center'
  //         },
  //         fill: {
  //           fgColor: { rgb: '80808080' }
  //         },
  //         font: {
  //           color: { rgb: 'ffffffff' },
  //           bold: true
  //         }
  //       }
  //     }
  //   }
  console.log(921, worksheet);

  if (autoWidth) {
    let minWidth = 10;
    worksheet['!cols'] = column.map((colItem) => {
      if (colItem.title === null) {
        return {
          wch: minWidth,
        };
      } else if (colItem.title.toString().charCodeAt(0) > 255) {
        return {
          wch:
            colItem.title.toString().length * 4 < minWidth
              ? minWidth
              : colItem.title.toString().length * 4,
        };
      } else {
        return {
          wch:
            colItem.title.toString().length * 2 < minWidth
              ? minWidth
              : colItem.title.toString().length * 2,
        };
      }
    });

    console.log(100, worksheet['!cols']);
    // for (const key in column) {
    //     let colItem = column[key]
    //     if (colItem.title === null) {
    //         return {
    //           wch: 10
    //         }
    //       } else if (colItem.title.toString().charCodeAt(0) > 255) {
    //         return {
    //           wch: colItem.title.toString().length * 2
    //         }
    //       } else {
    //         return {
    //           wch: colItem.title.toString().length
    //         }
    //       }
    //     result
    // }
    // const colWidth = data.map((row) =>
    //   row.map((val) => {
    //     if (val === null) {
    //       return {
    //         wch: 10
    //       }
    //     } else if (val.toString().charCodeAt(0) > 255) {
    //       return {
    //         wch: val.toString().length * 2
    //       }
    //     } else {
    //       return {
    //         wch: val.toString().length
    //       }
    //     }
    //   })
    // )
    // const result = colWidth[0]
    // for (let i = 1; i < colWidth.length; i++) {
    //   for (let j = 0; j < colWidth[i].length; j++) {
    //     if (result[j].wch < colWidth[i][j].wch) {
    //       result[j].wch = colWidth[i][j].wch
    //     }
    //   }
    // }
    // worksheet['!cols'] = result
  }
  //   worksheet['!cols'] = column.map(colItem=>{
  //     // if
  //     return {
  //         wpx:colItem.width,
  //     }
  //   })
  //   [
  //    { wpx: 140 }, { wpx: 140 }, { wpx: 140 }, { wpx: 60 }, { wpx: 100 }
  // ]
  const workbook = xlsxUtils.book_new();
  xlsxUtils.book_append_sheet(workbook, worksheet);
  writeFile(workbook, `${excelName || '导出'}.xlsx`, { compression: true });
};
