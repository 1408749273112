<template>
  <span>
    <span @click="show = true">
      <i class="el-icon-setting" />
    </span>
    <el-dialog
      :visible.sync="show"
      width="600px">
      <div class="flex-warp">
        <el-checkbox
          v-for="(item, key) in configNow"
          :key="key"
          :value="!configNow[key]"
          @change="change(key)">
          {{ key }}
        </el-checkbox>
      </div>
    </el-dialog>
  </span>
</template>
<script>
// import Vue from 'vue'
// import { Button,} from 'vant';
// Vue.use(Button);
export default {
  props: {
    path: {
      type: String,
      default: '',
    },
    column: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    // vueUpload,
  },
  data() {
    return {
      show: false,
      configNow: {},
    };
  },
  created() {
    let configNow = JSON.parse(
      localStorage.getItem('colFilter_' + this.path) || '{}'
    );
    console.log(51, this.path);
    let config = {};
    for (const key in this.column) {
      config[this.column[key].title] = false;
    }
    this.configNow = {
      ...config,
      ...configNow,
    };
    console.log(53, this.configNow);

    this.$emit(
      'update:column',
      this.column.map((item) => {
        return {
          ...item,
          hidden: this.configNow[item.title] || false,
        };
      })
    );
    console.log(68, this.column);
  },
  methods: {
    change(key) {
      this.configNow[key] = !this.configNow[key];
      localStorage.setItem(
        'colFilter_' + this.path,
        JSON.stringify(this.configNow)
      );
      // this.$emit('update:config', this.configNow);

      this.$emit(
        'update:column',
        this.column.map((item) => {
          return {
            ...item,
            hidden: this.configNow[item.title],
          };
        })
      );
    },
  },
};
</script>
<style scoped></style>
