import Vue from 'vue';
import Vuex from 'vuex';
import data from './data.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 全局loading动画
    loading: false,
    menu: [],
    // 记录所有请求，用于切换页面时取消
    requests: [],
    dict: data.dict,
    dictMap: data.dictMap,
    price: data.price,
    priceMap: data.priceMap,
    userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
    oss: {}, //uni.getStorageSync('oss') ||
  },
  mutations: {
    dict(state, data) {
      Object.assign(state.dict, data);
      let dictMap = {};
      for (let key in data) {
        let item = data[key];

        let dictMapItem = {};
        for (let key2 in item) {
          let item2 = item[key2];
          dictMapItem[
            item2.code === 0 ? item2.code : item2.code || item2.name
          ] = item2.name;
        }
        dictMap[key] = dictMapItem;
      }
      console.log(35, dictMap);
      Object.assign(state.dictMap, dictMap);
    },
    price(state, data) {
      Object.assign(state.price, data);
      state.priceMap = {};
      for (let key in state.price) {
        let item = state.price[key];
        state.priceMap[`${item.type}-${item.monthNum}`] = item.price;
      }
    },
    oss(state, data) {
      // console.log(32,data)
      Object.assign(state.oss, data);
      // uni.setStorageSync('oss', state.oss)
    },
    userInfo(state, data) {
      console.log(32, data);
      if (data === null) {
        state.userInfo = {};
      } else {
        Object.assign(state.userInfo, data);
      }
      // uni.setStorageSync("imUid", state.userInfo.id)
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
      // if (data) {
      // 	store.commit("accountPush", data)
      // }
    },
    loadStatus(state, boolean) {
      state.loading = boolean;
    },
    addRequest(state, string) {
      state.requests.push(string);
    },
  },
  actions: {
    oss(context, payload) {
      return new Promise((resolve, reject) => {
        if (context.state.oss.key) {
          return resolve(context.state.oss);
        }
        vm.$http('get tool/oss')
          .then((res) => {
            if (res.code !== 200) {
              return;
            }
            context.commit('oss', res.data);
            resolve(context.state.oss);
          })
          .finally((res) => {});
      });
    },
    user(context, payload) {
      return vm.$http('get user/info', {}).then((res) => {
        if (res.code == 200) {
          context.commit('userInfo', res.data);
        } else {
          // this.$u.toast(res.msg)
        }
      });
    },
  },
});
