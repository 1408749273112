<template>
  <div class="p10 flex-warp">
    <div
      v-for="(item, index) in srcArr"
      :key="item"
      class="bra6 mr10 mb10 flex"
      style="
        width: 148px;
        height: 148px;
        border: 1px solid #c0c4cc;
        position: relative;
      ">
      <img
        @click="(dialogImagesrc = item), (dialogVisible = true)"
        :src="item"
        style="width: 146px; height: 146px; object-fit: contain" />
      <el-popconfirm
        v-if="!disabled"
        title="确定删除吗？"
        palcement="topRight"
        @confirm="srcArr.splice(index, 1), srcChange()">
        <div
          slot="reference"
          class="f20 ml5"
          style="position: absolute; right: 10px; top: 0">
          <i class="el-icon-delete"></i>
        </div>
      </el-popconfirm>
    </div>
    <div
      v-if="srcArr.length < limit && !disabled"
      @click="selectFile"
      class="flex-c bra6 f20"
      style="
        width: 146px;
        height: 146px;
        border: 1px dashed #666;
        background-color: var(--el-fill-color-lighter);
      ">
      <i
        v-if="!loading"
        class="el-icon-plus f40"></i>
      <i
        v-if="loading"
        class="el-icon-loading f40"></i>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :modal="false">
      <img
        width="100%"
        :src="dialogImagesrc"
        alt="" />
    </el-dialog>
  </div>
</template>

<script>
import config from '@/lib/js/config.js';
import axios from 'axios';
export default {
  props: {
    src: '',
    limit: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      srcArr: [],
      dialogImagesrc: '',
      dialogVisible: false,
      loading: false,
      base: config.http.base,
      token: localStorage.getItem('token'),
    };
  },
  computed: {
    oss() {
      return this.$store.state.oss;
    },
  },
  watch: {
    src: {
      handler() {
        console.log(39, this.src);
        // if()
        this.srcArr = (this.src || '').split(',').filter((item) => !!item);
      },
      immediate: true,
    },
  },
  methods: {
    srcChange() {
      console.log(187, this.srcArr);

      console.log(45, this.srcArr.join(','));
      console.log(45.1, this.src);

      this.$emit('update:src', this.srcArr.filter((item) => !!item).join(','));

      console.log(50, this.src);
      console.log(50.1, this.srcArr);
    },
    selectFile(index) {
      let input = document.createElement('input');
      input.setAttribute('type', 'file');
      // input.setAttribute('multiple', 'multiple');
      input.accept = 'image/*';
      // this.$store.dispatch('oss')
      input.addEventListener('change', (e, e2, e3) => {
        // console.log(365.0, e,e2,e3);
        // console.log(365.1, e.path,e.target);
        // console.log(366.1, e.target.files);
        // console.log(367.1, e.target.files[0]);
        // console.log(368.1, e.target.files);
        // console.log(365.1, e.path);
        // console.log(365.2, e.path[0]);
        // console.log(365.3, e.target.files);
        // console.log(365.4, e.target.files[0]);
        console.log(413, e.target);
        // this.clipImage.show = true
        // this.option.img = e.target.files[0]

        // this.option.img = window.src.createObjectsrc(e.target.files[0])
        let imgFile = e.target.files[0];
        // console.log(68, oss);

        // 在这里进行一系列的校验
        this.loadingObj = true;

        const formData = new FormData();

        formData.append('file', imgFile);
        console.log(117, this.token);

        axios
          .post(this.base + 'common/upload', formData, {
            headers: {
              Authorization: this.token,
              'Content-type': 'multipart/form-data',
            },
          })
          .then((axiosRes) => {
            console.log(396, axiosRes);
            // if (axiosRes) {

            // }

            let res = axiosRes.data;
            console.log(226, res);

            this.srcArr.push(res.data);
            this.srcChange();
          })
          .catch((err) => {
            console.log(124, err);
          })
          .finally(() => {
            this.loadingObj = false;
          });
      });
      input.click();
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-uploader {
  v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
</style>
