let data = {
  dict: {
    versionType: [
      {
        code: 1,
        name: '正式版',
      },
      {
        code: 2,
        name: '试用版',
      },
    ],
    merchandiseType: [
      {
        code: 1,
        name: '初级版',
      },
      {
        code: 2,
        name: '专业版',
      },
      {
        code: 3,
        name: '企业版',
      },
      {
        code: 4,
        name: '高级企业版',
      },
    ],
    orderTime: [
      {
        code: 1,
        name: '月度',
      },
      {
        code: 6,
        name: '半年度',
      },
      {
        code: 12,
        name: '年度',
      },
      {
        code: 0,
        name: '自定义',
      },
    ],
    payType: [
      {
        code: 3,
        name: '对公支付',
      },
      {
        code: 1,
        name: '支付宝',
      },
      {
        code: 2,
        name: '微信',
      },
      {
        code: 4,
        name: '线下支付宝',
      },
    ],
    orderType: [
      {
        code: 1,
        name: '新购',
      },
      {
        code: 2,
        name: '续签',
      },
      {
        code: 3,
        name: '升级',
      },
      {
        code: 4,
        name: '续签升级',
      },
    ],
    channelType: [
      {
        code: null,
        name: '无',
      },
      {
        code: null,
        name: '百度推广',
      },
      {
        code: null,
        name: '360',
      },
      {
        code: null,
        name: 'b站文章推广',
      },
      {
        code: null,
        name: '知乎文章推广',
      },
      {
        code: null,
        name: '百度自然搜索',
      },
      {
        code: null,
        name: '360自然搜索',
      },
      {
        code: null,
        name: '搜狗自然搜索',
      },
      {
        code: null,
        name: 'bing',
      },
      {
        code: null,
        name: 'google',
      },
      {
        code: null,
        name: '直接访问',
      },
      {
        code: null,
        name: '公众号',
      },
    ],
    belong: [
      {
        code: null,
        name: 'liyang',
      },
      {
        code: null,
        name: '官网支付',
      },
      {
        code: null,
        name: '用户运营',
      },
      {
        code: null,
        name: '邱秋',
      },
    ],
    orderStatus: [
      {
        code: 1,
        name: '已支付',
      },
      {
        code: 2,
        name: '已退款',
      },
      {
        code: 3,
        name: '待财务确认',
      },
      {
        code: 4,
        name: '财务驳回',
      },
      {
        code: 5,
        name: '已到期',
      },
      {
        code: 6,
        name: '退款确认中',
      },
      {
        code: 7,
        name: '退款驳回',
      },
      {
        code: 8,
        name: '部分退款',
      },
    ],
    invoiceStatus: [
      {
        code: 1,
        name: '已开具',
      },
      {
        code: 2,
        name: '待开具',
      },
      {
        code: 3,
        name: '已作废',
      },
      {
        code: 4,
        name: '待提交',
      },
      {
        code: 5,
        name: '不可开具',
      },
    ],
    payStatus: [
      {
        code: 0,
        name: '预支付',
      },
      {
        code: 1,
        name: '支付中',
      },
      {
        code: 2,
        name: '支付成功',
      },
      {
        code: 3,
        name: '支付失败',
      },
      {
        code: 4,
        name: '取消支付',
      },
    ],
    refundType: [
      {
        code: 1,
        name: '全额退款取消权限',
      },
      {
        code: 2,
        name: '部分退款保留权限',
      },
    ],
    refundPayType: [
      {
        code: 1,
        name: '支付宝',
      },
      {
        code: 2,
        name: '微信',
      },
      {
        code: 3,
        name: '银行卡',
      },
    ],
  },
  price: [
    {
      id: 1,
      type: 1,
      title: '初级版会员',
      summary: '32133213123',
      price: 9900,
      originalPrice: 59900,
      monthNum: 1,
      dayPrice: 300,
      isDefault: 0,
      createTime: '2019-08-16 11:46:36',
      updateTime: '2023-05-09 10:16:18',
      status: 1,
    },
    {
      id: 2,
      type: 1,
      title: '初级版会员',
      summary: '4234',
      price: 47500,
      originalPrice: 359400,
      monthNum: 6,
      dayPrice: 200,
      isDefault: 0,
      createTime: '2019-08-16 11:52:09',
      updateTime: '2023-05-09 10:18:13',
      status: 1,
    },
    {
      id: 3,
      type: 1,
      title: '初级版会员',
      summary: '31231',
      price: 71200,
      originalPrice: 718800,
      monthNum: 12,
      dayPrice: 200,
      isDefault: 1,
      createTime: '2019-08-16 11:52:38',
      updateTime: '2023-05-09 10:18:59',
      status: 1,
    },
    {
      id: 4,
      type: 2,
      title: '专业版会员 ',
      summary: '',
      price: 89900,
      originalPrice: 109900,
      monthNum: 1,
      dayPrice: 3000,
      isDefault: 0,
      createTime: '2019-12-23 14:24:42',
      updateTime: '2020-11-20 15:32:24',
      status: 1,
    },
    {
      id: 5,
      type: 2,
      title: '专业版会员',
      summary: '',
      price: 383500,
      originalPrice: 539400,
      monthNum: 6,
      dayPrice: 2100,
      isDefault: 0,
      createTime: '2019-12-24 23:56:11',
      updateTime: '2021-05-14 20:16:35',
      status: 1,
    },
    {
      id: 6,
      type: 2,
      title: '专业版会员',
      summary: '1',
      price: 575200,
      originalPrice: 1078800,
      monthNum: 12,
      dayPrice: 1500,
      isDefault: 1,
      createTime: '2019-12-24 23:58:22',
      updateTime: '2021-05-14 20:16:31',
      status: 1,
    },
    {
      id: 7,
      type: 3,
      title: '企业版会员',
      summary: '1',
      price: 289900,
      originalPrice: 339900,
      monthNum: 1,
      dayPrice: 9600,
      isDefault: 0,
      createTime: '2019-12-25 00:00:22',
      updateTime: '2022-05-05 11:26:20',
      status: 1,
    },
    {
      id: 8,
      type: 3,
      title: '企业版会员',
      summary: '',
      price: 1391500,
      originalPrice: 2039400,
      monthNum: 6,
      dayPrice: 7700,
      isDefault: 0,
      createTime: '2019-12-25 00:01:24',
      updateTime: '2022-05-05 11:30:01',
      status: 1,
    },
    {
      id: 9,
      type: 3,
      title: '企业版会员',
      summary: '',
      price: 2087200,
      originalPrice: 4078800,
      monthNum: 12,
      dayPrice: 5700,
      isDefault: 1,
      createTime: '2019-12-25 00:02:17',
      updateTime: '2022-05-05 11:28:03',
      status: 1,
    },
    {
      id: 10,
      type: 4,
      title: '高级企业版会员',
      summary: '',
      price: 419900,
      originalPrice: 419900,
      monthNum: 1,
      dayPrice: 14000,
      isDefault: 0,
      createTime: '2021-12-07 00:00:22',
      updateTime: '2022-03-04 10:23:31',
      status: 1,
    },
    {
      id: 11,
      type: 4,
      title: '高级企业版会员',
      summary: '',
      price: 1969500,
      originalPrice: 2519400,
      monthNum: 6,
      dayPrice: 10900,
      isDefault: 0,
      createTime: '2021-12-07 00:01:24',
      updateTime: '2022-05-05 11:30:30',
      status: 1,
    },
    {
      id: 12,
      type: 4,
      title: '高级企业版会员',
      summary: '',
      price: 2959900,
      originalPrice: 5038800,
      monthNum: 12,
      dayPrice: 8100,
      isDefault: 1,
      createTime: '2021-12-07 00:02:17',
      updateTime: '2022-03-04 10:26:27',
      status: 1,
    },
  ],
};

data.dictMap = {};
for (let key in data.dict) {
  let item = data.dict[key];

  let dictMapItem = {};
  for (let key2 in item) {
    let item2 = item[key2];

    dictMapItem[item2.code || item2.name] = item2.name;
  }
  data.dictMap[key] = dictMapItem;
}
// for(let key in data.dict){
//   let item = data.dict[key]
//   data.dictMap[key] = {}
//   for(let key2 in item){
//     let item2 = item[key2]
//     data.dictMap[key][item2.code || item2.name] = item2.name
//   }
// }
data.priceMap = {};
for (let key in data.price) {
  let item = data.price[key];
  data.priceMap[`${item.type}-${item.monthNum}`] = item.price;
}
export default data;
