let isDev = process.env.NODE_ENV === 'development';
console.log(222, process.env.NODE_ENV);

let online = '/';

let dev = 'http://127.0.0.1:8188/api/';
// dev = 'http://bzmanager.666.hsydata.com/api/';

isDev = false

if (window.SERVER_ONLINE) {
  online = window.SERVER_ONLINE;
}

export default {
  http: {
    base: isDev ? dev : `${online}`,
  },
};
