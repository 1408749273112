import Vue from 'vue';
import VueRouter from 'vue-router';
let vm = Vue.prototype;
Vue.use(VueRouter);

// fix：重复路径跳转报错
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

// isMenu 作为菜单显示
const router = new VueRouter({
  mode: 'hash',
  // base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '/',
      component: () => import('@/layout/index.vue'),
      redirect: '/zhengzhou/index',
      meta: {
        title: '支出',
        isMenu: true,
      },
      children: [
        {
          path: '/zhengzhou/index',
          name: '/zhengzhou/index',
          component: () => import('@/views/zhengzhou/index.vue'),
          meta: {
            title: '支出',
            isMenu: true,
            roles: ['admin', 'common'],
            icon: 'iconfont icon-shouye',
          },
        },
        {
          path: '/zhengzhou/index2',
          name: '/zhengzhou/index2',
          component: () => import('@/views/zhengzhou/index2.vue'),
          meta: {
            title: '收入',
            isMenu: true,
            roles: ['admin', 'common'],
            icon: 'iconfont icon-shouye',
          },
        },
      ],
    },
  ],
});

const whiteList = ['/login']; // no redirect whitelist
// router.beforeEach(async (to, from, next) => {
//   console.log(89, to, whiteList.includes(to.path));
//   if (whiteList.includes(to.path)) {
//     next();
//   } else {
//     const hasToken = localStorage.getItem('token');
//     console.log(67, hasToken);
//     if (hasToken) {
//       next();
//     } else {
//       console.log(71);
//       next('/login');
//     }
//   }
// });

export default router;
