import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// import "echarts"
// import Echarts from "vue-echarts"
// Vue.component("Echarts", Echarts)

import { requireImg } from '@/utils/requireImg';
Vue.prototype.requireImg = requireImg;

import '@/assets/css/common.scss';

import Loading from 'element-ui/lib/loading';
Vue.use(Loading.directive);

import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
Vue.use(VXETable);

import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Element);

import upload from '@/components/upload.vue';
Vue.component('upload', upload);
import colFilter from '@/components/colFilter.vue';
Vue.component('colFilter', colFilter);
import colFilter2 from '@/components/colFilter2.vue';
Vue.component('colFilter2', colFilter2);

import http from '@/lib/js/http';

Vue.prototype.$http = http;

import excelExport from '@/lib/js/excelExport2';
Vue.prototype.$excelExport = excelExport;

Vue.prototype.$store = store;
import axios from 'axios';
Vue.prototype.$axios = axios;
// Vue.prototype.S = localStorage
Vue.prototype.$err = Vue.prototype.$message.error;
Vue.prototype.$suc = Vue.prototype.$message.success;
Vue.prototype.$log = console.log;
Vue.prototype.$getOss = () => {
  return new Promise((resolve, reject) => {
    if (store.state.oss.key) {
      return resolve(store.state.oss);
    }
    http('get tool/oss')
      .then((res) => {
        if (res.code !== 200) {
          return;
        }
        store.commit('oss', res.data);
        resolve(store.state.oss);
      })
      .finally((res) => {});
  });
};
Vue.prototype.$upload = (event) => {
  console.log(65, event);

  let oss = store.state.oss;
  console.log(68, oss);

  const flie = event.file;
  // 在这里进行一系列的校验
  const formData = new FormData();
  let urlPath = 'laidong/' + Date.now() + Math.random();

  formData.append('key', urlPath);
  formData.append('policy', oss.policyBase64);
  formData.append('OSSAccessKeyId', oss.key);
  formData.append('signature', oss.signature);
  formData.append('success_action_status', 200);
  formData.append('cache-control', 'public');

  formData.append('file', flie);
  return axios
    .post(oss.endpoint, formData, {
      'Content-type': 'multipart/form-data',
    })
    .then(
      (res) => {
        // 上传成功后的处理
        console.log(81, res);
      },
      (err) => {
        // 出现错误时的处理
      }
    );
};
Vue.prototype.$timeFormat = function (timestamp, fmt = 'yyyy-MM-dd') {
  //author: meizz
  let date = new Date(Number(timestamp) * 1000);
  var o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      );
  return fmt;
};
let dict2 = {
  ID_type: [
    { name: '未知', code: '0' },
    { name: '个人', code: '1' },
    { name: 'UP主', code: '2' },
    { name: '品牌方', code: '3' },
    { name: '广告媒体', code: '4' },
    { name: '机构', code: '5' },
    { name: '学生', code: '6' },
    { name: '内部', code: '100' },
  ],
  channel: [
    { name: '无', code: '无' },
    { name: '百度推广', code: '百度推广' },
    { name: '360推广', code: '360推广' },
    { name: '公众号', code: '公众号' },
    { name: 'b站文章推广', code: 'b站文章推广' },
    { name: '知乎文章推广', code: '知乎文章推广' },
    { name: '百度自然搜索', code: '百度自然搜索' },
    { name: '360自然搜索', code: '360自然搜索' },
    { name: '搜狗自然搜索', code: '搜狗自然搜索' },
    { name: 'bing', code: 'bing' },
    { name: 'bing推广', code: 'bing推广' },
    { name: 'google', code: 'google' },
    { name: '直接访问', code: '直接访问' },
  ],
  follow_up_intent: [
    { name: 'S', code: 'S' },
    { name: 'A', code: 'A' },
    { name: 'B', code: 'B' },
    { name: 'C', code: 'C' },
    { name: 'D', code: 'D' },
  ],
  follow_up_typeArr: [
    { name: '电话', code: '电话' },
    { name: '微信', code: '微信' },
    { name: '钉钉', code: '钉钉' },
    { name: '其他', code: '其他' },
  ],
  isChild: [
    { name: '是', code: '1' },
    { name: '否', code: '0' },
  ],
  status: [
    { name: '正常', code: 1 },
    { name: '无效', code: 0 },
    { name: '删除', code: -1 },
    { name: '注销', code: 2 },
  ],
  auth_status: [
    { name: '未认证', code: 0 },
    { name: '已认证', code: 100 },
    { name: '认证驳回', code: -100 },
  ],
  follow_up_nameArr: [
    { name: '未跟进', code: '未跟进' },
    { name: 'liyang', code: 'liyang' },
    { name: 'yonghuyunying', code: 'yonghuyunying' },
    { name: 'yali', code: 'yali' },
  ],
  brandType: [
    { name: '未关联分类', code: '0' },
    { name: '商品品牌', code: '1' },
    { name: 'APP应用', code: '2' },
    { name: '游戏应用', code: '3' },
  ],
  brandStatus: [
    { name: '无效', code: '0' },
    { name: '正常', code: '1' },
    { name: '大数据不计算', code: '2' },
  ],
  is_open: [
    { name: '开启', code: '1' },
    { name: '关闭', code: '0' },
  ],
  statusBuyLog: [
    { name: '无效', code: 0 },
    { name: '正常', code: 1 },
    { name: '删除', code: -1 },
    { name: '待处理', code: 2 },
  ],
};

Vue.mixin({
  data() {
    return {};
  },
  computed: {
    $clientHeight() {
      return document.documentElement.clientHeight;
    },
    $user() {
      return this.$store.state.userInfo;
    },
    $dict() {
      return this.$store.state.dict;
    },
    $dictMap() {
      return this.$store.state.dictMap;
    },
    $dict2() {
      return dict2;
    },
    $dict2Map() {
      let dictMap = {};
      for (let key in dict2) {
        let item = dict2[key];

        let dictMapItem = {};
        for (let key2 in item) {
          let item2 = item[key2];
          dictMapItem[
            item2.code === 0 ? item2.code : item2.code || item2.name
          ] = item2.name;
        }
        dictMap[key] = dictMapItem;
      }
      console.log(167, dictMap);
      return dictMap;
    },
    $priceMap() {
      return this.$store.state.priceMap;
    },
  },
  methods: {
    $open(param) {
      console.log(202, param);
      if (!param) {
        return;
      }
      if (typeof param === 'string') {
        window.open(param);
        return;
      }
      // console.log(28, vm)
      // const { $router } = vm
      // $router.push(param)
      console.log(31, this.$router);
      const { href } = this.$router.resolve(param);
      console.log(33, href);
      window.open(href, '_blank');
    },
    $init() {
      // this.$http('get dict', {})
      //   .then((res) => {
      //     // if (res.code!==200) {
      //     // 	return this.$err(res.msg)
      //     // }
      //     res.data.refundType = [
      //       { name: '全额退款取消权限', code: 1 },
      //       { name: '部分退款保留权限', code: 2 },
      //     ];
      //     this.$store.commit('dict', res.data);
      //     // console.log(20,this.$dict);
      //     // console.log(21,this.$dictMap);
      //   })
      //   .finally((res) => {});
      // this.$http('get order/office/price', {})
      //   .then((res) => {
      //     // if (res.code!==200) {
      //     // 	return this.$err(res.msg)
      //     // }
      //     this.$store.commit('price', res.data);

      //     // console.log(30,this.$store.state.price);
      //     // console.log(31,this.$priceMap);
      //   })
      //   .finally((res) => {});
    },
    $filter(model, data) {
      let re = {};
      for (let key in model) {
        let item = model[key];
        re[key] = data[key];
      }
      return re;
    },
    $logout() {
      console.log(111);
      localStorage.removeItem('token');
      this.$store.commit('userInfo', null);
      this.$router.push('/login');
    },
    $copy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    $copyTxt(data){
      const textarea = document.createElement("textarea")
      textarea.value = data
      textarea.readOnly = true
      document.body.appendChild(textarea)
      textarea.select()
      textarea.setSelectionRange(0, data.length)
      // document.addEventListener("copy", function (e) {
      //   console.log(316,e
      //     ,e.target);
      //   e.clipboardData.setData("text", e.target);
      //   console.log(319,e.clipboardData.getData("text"));
        
        
      //   // document.removeEventListener("copy", function () {})
      // })
      document.execCommand("copy")
      textarea.remove()
      this.$suc("复制成功")
    },
    $downExcel(res, name) {
      // 处理文件流
      // console.log(156,res);
      // const reader = new FileReader();
      // reader.readAsText(res,"UTF-8");
      // reader.onload = (e) => {
      //   // console.log(44, e)
      //   const blob = new Blob([res], {
      //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      //   });
      //   const link = document.createElement('a');
      //   link.href = window.URL.createObjectURL(blob);
      //   link.download = `${name}_${this.$timeFormat(
      //     Date.now() / 1000,
      //     'YYYYMMDDHHmmss'
      //   )}.xls`;
      //   link.click();
      // };

      // const blob = new Blob([res], {
      //     type: 'application/vnd.ms-excel;charset=utf-8',
      //   });
      //   const link = document.createElement('a');
      //   link.href = window.URL.createObjectURL(blob);
      //   link.download = `${name}_${this.$timeFormat(
      //     Date.now() / 1000,
      //     'yyyyMMddhhmmss'
      //   )}.xls`;
      //   link.click();

      // const blob = new Blob([res], {
      //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      // });
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = `hsy_web_order1704858254495.xlsx.xlsx`;
      // link.click();

      let blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      const element = document.createElement('a');
      const href = URL.createObjectURL(blob);
      element.href = href;
      element.setAttribute('download', name);
      element.style.display = 'none';
      element.click();
      //调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
      URL.revokeObjectURL(href);
      element.remove();
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
