<template>
  <span>
    <span @click="show = true">
      <i class="el-icon-setting" />
    </span>
    <el-dialog
      :visible.sync="show"
      width="600px">
      <div class="flex-warp">
        <el-checkbox
          v-for="(item, key) in configNow"
          :key="key"
          :value="configNow[key]"
          @change="change(key)">
          {{ key }}
        </el-checkbox>
      </div>
    </el-dialog>
  </span>
</template>
<script>
// import Vue from 'vue'
// import { Button,} from 'vant';
// Vue.use(Button);
export default {
  props: {
    path: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    // vueUpload,
  },
  data() {
    return {
      show: false,
      configNow: {},
    };
  },
  created() {
    let configNow = JSON.parse(
      localStorage.getItem('colFilter_' + this.path) || '{}'
    );
    this.configNow = {
      ...this.config,
      ...configNow,
    };
    console.log(53, configNow);
    this.$emit('update:config', this.configNow);
  },
  methods: {
    change(key) {
      this.configNow[key] = !this.configNow[key];
      localStorage.setItem(
        'colFilter_' + this.path,
        JSON.stringify(this.configNow)
      );
      this.$emit('update:config', this.configNow);
    },
  },
};
</script>
<style scoped></style>
