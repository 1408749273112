import axios from 'axios';
import qs from 'qs';
import config from './config';
import Vue from 'vue';

import router from '@/router';

const vm = Vue.prototype;
console.log(5, config);
// const service = axios.create({
//   baseURL: baseUrl, // api base_url
//   timeout: 60000, // 请求超时时间
// })
// service.interceptors.response.use((response) => {
//   return response.data
// })
const http = (methodPath, param, cusConfig = {}) => {
  // const aid = vm.$store.state.aid
  // 以空格分隔 method 和 path
  const methodPathArr = methodPath.split(' ');
  let method = methodPathArr[0];
  const path = methodPathArr[1];
  // 兼容 del 别名
  method === 'del' ? (method = 'delete') : '';

  let axiosParam = {
    method: method,
    url: `${config.http.base}${path}`,
    headers: {
      // "Content-type": "application/x-www-form-urlencoded",
      'Content-type': 'application/json',
      // "aid": aid,
    },
  };

  if (method === 'blob') {
    axiosParam = {
      ...axiosParam,
      method: 'get',
      responseType: 'blob', // 请求的数据
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    method = 'get';
  }

  const token = localStorage.getItem('token');
  if (token) {
    axiosParam.headers.Authorization = token;
  }

  switch (method) {
    case 'get':
    case 'delete':
      axiosParam.params = param;
      break;
    case 'post':
    case 'put':
      axiosParam.data = param;
      break;
    default:
      break;
  }

  return new Promise((resolve, reject) => {
    // console.log(30);

    try {
      axios({
        ...cusConfig,
        ...axiosParam,
      }).then((res) => {
        console.log(74, res);
        for (let key in res.headers) {
          let item = res.headers[key];
          console.log(key, item);
        }
        // let res = resAxios.data
        // console.log(38)
        // console.log(38, res.data)
        // if (methodOri == "blob") {

        //   let blob = new Blob([res], { type: res.headers["content-type"] });
        //   const element = document.createElement("a");
        //   const href = URL.createObjectURL(blob);
        //   element.href = href;
        //   element.setAttribute("download", res.headers["Content-disposition"].substring(28));
        //   element.style.display = "none";
        //   element.click();
        //   //调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
        //   URL.revokeObjectURL(href);
        //   element.remove();
        // }
        const data = res.data;
        if (data.message) {
          data.msg = data.message;
        }
        if (data.code) {
          data.code = Number(data.code);
        }
        let pages;
        let page;
        switch (data.code) {
          case 401: // 401 未登录，403 拒绝访问，无权限访问
            console.log(45, data);
            new Promise((resolve2) => {
              // pages = getCurrentPages()
              // page = pages[pages.length - 1]
              // console.log(43, pages, page)
              // currentWebview = pages[pages.length - 1].$getAppWebview()
              // if (currentWebview.__uniapp_route
              // != "pages/msg-center/msg-center") {
              //   uni.switchTab({url: "/pages/msg-center/msg-center"})
              // }
              console.log(84, vm);
              localStorage.removeItem('token');
              router.push('/login');
              // location.reload();
              // if (page && [
              //     "pages/user/register",
              //     "pages/user/login",
              //   ].includes(page.route)) {
              //   // vm.$store.commit(
              //   //     "loginRedirect",
              //   //     `/${currentWebview.__uniapp_route}`,
              //   // )

              //   vm.$redi("/pages/user/login")
              // } else {
              //   // vm.$store.commit("loginRedirect", `/pages/index/index`)

              //   vm.$redi("/pages/user/login")
              // }
              resolve2();
            });

            // uni.showToast({
            // title:''
            // })
            reject(res);
            break;
          default:
            resolve(res.data);
            break;
        }
      });
    } catch (error) {
      console.log(90);
      reject(error);
    }
  });
};

export default http;
